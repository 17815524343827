































































































































































































import { Vue, Component } from 'vue-property-decorator'
import moment from 'moment'
import { userMapper } from '@/store/modules/user'
import LoadFileButton from '@/components/ui/LoadFileButton.vue'
import ChangePasswordModal from '@/components/common/modal/ChangePasswordModal.vue'
import ChangeMailModal from '@/components/content/modal/ChangeMailModal.vue'
import NotificationsSettingsModal from '@/components/content/modal/NotificationsSettingsModal.vue'
import AwesomeMask from 'awesome-mask'
import UserFactory from '@/factories/user'
import { roles } from '@/config/types/roles'
import Portfolio from '@/views/profile/Portfolio.vue'
import { regFIO } from '@/config/default/regex'
import { isValidLoadedFile, onImageInput } from '@/utils/images'
import { UserAdapter } from '@/api/adapters/user'
import _ from 'lodash'
import { toastMapper } from '@/store/modules/toast'
import { defaultProfile } from '@/config/default/defaultImages'
import UserAPI from '@/api/requests/user'
import { fileFormats } from '@/config/default/extensions'
import {
  PermissionManager,
  permissions
} from '@/api/permissions/PermissionManager'
import { userPhoneLength } from '@/config/default/user'
import { phoneMask } from '@/config/default/masks'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser', 'isStudent', 'userOrganizations'])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast']),
    ...userMapper.mapActions(['fetchCurrentUser'])
  }
})

@Component({
  components: {
    LoadFileButton,
    ChangePasswordModal,
    ChangeMailModal,
    NotificationsSettingsModal,
    Portfolio
  },
  directives: {
    mask: AwesomeMask
  }
})
export default class Profile extends Mapper {
  $refs!: { notificationsSettingsModal: NotificationsSettingsModal }

  private phoneMask = phoneMask
  private defaultAvatar = defaultProfile
  private userInfo = UserFactory.emptyUserProfile()
  private canEdit = false
  private isDisplayPortfolio = false

  private async created() {
    await this.fetchCurrentUser(true)
    if (
      !_.isNil(this.$router.currentRoute.params.id) &&
      PermissionManager.common().can(permissions.viewProfile, undefined)
    ) {
      this.canEdit = true
      const userId = _.toNumber(this.$router.currentRoute.params.id)
      const response = await UserAPI.getProfile(userId)
      if (response.data.roles.includes(roles.student)) {
        this.isDisplayPortfolio = true
      }
      this.userInfo = response.data
      this.userInfo = UserAdapter.getFieldsUser(this.userInfo)
    } else {
      this.userInfo = UserAdapter.getFieldsUser(this.currentUser)
    }
  }
  get essentialFieldsNotEmpty() {
    return this.currentUser.surname && this.currentUser.name
  }
  private onChangePasswordClick() {
    this.$bvModal.show('changePasswordModalId')
  }
  private onChangeMailClick() {
    this.$bvModal.show('changeMailModalId')
  }
  private onSettingsNotificationsClick() {
    this.$refs.notificationsSettingsModal.openModal()
    this.$bvModal.show('settingsNotificationsModalId')
  }
  private async deleteClicked() {
    this.userInfo.avatarUrl = this.defaultAvatar
  }
  private async onClickImage(e: any) {
    const { isOk, error } = isValidLoadedFile(
      e.target.files[0],
      fileFormats.image
    )
    if (e.target.files && e.target.files[0] && isOk) {
      const postUser = _.cloneDeep(this.userInfo)
      const userId = postUser.id
      if (userId) {
        this.userInfo.avatarUrl = await onImageInput(e.target.files[0])
      }
    } else {
      this.pushToast({
        error: true,
        time: 5,
        title: 'Ошибка загрузки файла',
        message: error
      })
    }
  }
  private onValidationSurname(): boolean {
    return regFIO.test(this.userInfo.surname)
  }
  private onValidationName() {
    return regFIO.test(this.userInfo.name)
  }
  private onValidationMidname() {
    return !_.isEmpty(this.userInfo.midname)
      ? regFIO.test(this.userInfo.midname)
      : true
  }
  private get listOrganizations() {
    return this.userInfo.organizations.map(epl => epl.title).join(', ')
  }
  private isCorrectDate(userDate: Date) {
    const permanentDate = moment('1900-01-01')
    const currentDate = moment(new Date())
    return (
      moment(userDate).isBefore(currentDate) &&
      moment(userDate).isAfter(permanentDate)
    )
  }
  private async onSaveClick() {
    if (this.userInfo.id) {
      const name = this.userInfo.name
      if (name.trim() === '') {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Имя - обязательное поле',
          time: 5
        })
        return
      }
      const surname = this.userInfo.surname
      if (surname.trim() === '') {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Фамилия - обязательное поле',
          time: 5
        })
        return
      }
      const phone = this.userInfo.phone.match(/\d/g)?.join('').length ?? 0
      if (phone < userPhoneLength && this.userInfo.phone.length > 0) {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Введите корректный телефон',
          time: 5
        })
      } else if (!this.isCorrectDate(new Date(this.userInfo.birthday))) {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Введите корректную дату рождения',
          time: 5
        })
      } else {
        const user = _.cloneDeep(this.userInfo)
        const response = await UserAPI.editUser(user)
        if (response.status === 200) {
          this.pushToast({
            time: 5,
            title: 'Изменение данных',
            message: 'Данные успешно изменены!'
          })
        } else {
          this.pushToast({
            error: true,
            title: 'Ошибка',
            message: 'Не удалось сохранить данные',
            time: 5
          })
        }
        await this.fetchCurrentUser(true)
        this.userInfo = UserAdapter.getFieldsUser(this.currentUser)
      }
    }
  }
}
